import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from "styled-components";
import SimpleHeat from "../helpers/SimpleHeat";
import Selected from "./Selected";
import { v4 as uuid } from 'uuid';
import {useSettingsContext} from "./HeatMapResult";

const Wrapper = styled.div`
  padding: 40px;
  border: 2px dashed #eee;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`

const CanvasWrapper = styled.div`
  position: relative;
  display: flex;
  border: 1px solid #eee;

  & > canvas {
    max-width: 100%;
    
    ${({$imageFit}) => $imageFit && css`
        max-height: calc(100vh - 310px);
    `}
  }
`

const ImageCanvas = styled.canvas`
  position: absolute;
  left: 0;
  top: 0;
`

const HeatCanvas = styled.canvas`
  position: absolute;
  left: 0;
  top: 0;
`

const Selection = styled.div`
  position: absolute;
  background-color: #87dbff;
  opacity: 50%;
  border: 1px dashed white;
`

const Canvas = ({img, data, imageFit}) => {
    const canvasWrapperRef = useRef(null)
    const baseRef = useRef(null)
    const imageRef = useRef(null)
    const heatRef = useRef(null)
    const selectionRef = useRef(null)

    const {layers} = useSettingsContext()

    const [selected, setSelected] = useState([]);

    let isMouseDown = false;
    let top, left, bottom, right;

    useEffect(() => {
        if (img) {
            baseRef.current.width = img.width;
            baseRef.current.height = img.height;
            heatRef.current.width = img.width;
            heatRef.current.height = img.height;
            imageRef.current.width = img.width;
            imageRef.current.height = img.height;

            let imgCtx = imageRef.current.getContext('2d');

            imgCtx.drawImage(img, 0, 0)

            const formattedData = data.map(item => [item.left * img.width, item.top * img.height, 0.65]);

            SimpleHeat(heatRef.current).data(formattedData).draw();
        }
    }, [img]) // eslint-disable-line react-hooks/exhaustive-deps

    const setSelectionStyles = (styles) => {
        Object.assign(selectionRef.current.style, styles)
    }

    const onMouseDown = (e) => {
        if (layers.selected) {
            left = (e.pageX - canvasWrapperRef.current.offsetLeft) / canvasWrapperRef.current.offsetWidth;
            top = (e.pageY - canvasWrapperRef.current.offsetTop) / canvasWrapperRef.current.offsetHeight;
            // setSelectedCount(0)
            selectionRef.current.style.left = 0;
            selectionRef.current.style.top = 0;
            selectionRef.current.style.width = 0;
            selectionRef.current.style.height = 0;
            isMouseDown = true;
        }
    }

    const onMouseMove = (e) => {
        if (isMouseDown && layers.selected) {
            right = (e.pageX - canvasWrapperRef.current.offsetLeft) / canvasWrapperRef.current.offsetWidth;
            bottom = (e.pageY - canvasWrapperRef.current.offsetTop) / canvasWrapperRef.current.offsetHeight;

            const minX = Math.min(left, right);
            const maxX = Math.max(left, right);
            const minY = Math.min(top, bottom);
            const maxY = Math.max(top, bottom);

            setSelectionStyles({
                left: minX * 100 + "%",
                top: minY * 100 + "%",
                width: (maxX - minX) * 100 + "%",
                height: (maxY - minY) * 100 + "%"
            })
        }
    }

    const onMouseUp = () => {
        if (isMouseDown && layers.selected) {
            isMouseDown = false;

            const minX = Math.min(left, right);
            const maxX = Math.max(left, right);
            const minY = Math.min(top, bottom);
            const maxY = Math.max(top, bottom);

            const filteredData = data.filter(item => {
                const itemX = item.left
                const itemY = item.top
                return minX <= itemX && itemX <= maxX && minY <= itemY && itemY <= maxY
            });

            if (filteredData.length) {
                setSelected(prev => {
                    return [
                        ...prev,
                        {
                            id: uuid(),
                            top: minY * 100 + "%",
                            left: minX * 100 + "%",
                            width: (maxX - minX) * 100 + "%",
                            height: (maxY - minY) * 100 + "%",
                            amount: Math.floor(filteredData.length / data.length * 100)
                        }
                    ]
                })
            }

            setSelectionStyles({
                left: 0,
                top: 0,
                width: 0,
                height: 0
            })
        }
    }

    const removeIndex = (id) => {
        if (layers.selected) {
            const newSelected = [...selected];
            const removeIndex = newSelected.findIndex(item => item.id === id);
            newSelected.splice(removeIndex, 1)
            setSelected(newSelected)
        }
    }

    return (
        <Wrapper onMouseUp={onMouseUp} onMouseDown={onMouseDown} onMouseMove={onMouseMove}>
            <CanvasWrapper ref={canvasWrapperRef} $imageFit={imageFit}>
                <canvas ref={baseRef}/>
                <ImageCanvas ref={imageRef} style={{display: layers.image ? "block" : "none"}}/>
                <HeatCanvas ref={heatRef} style={{display: layers.heatmap ? "block" : "none"}}/>
                <Selection ref={selectionRef}/>
                {layers.selected && <Selected selected={selected} remove={removeIndex}/>}
            </CanvasWrapper>
        </Wrapper>
    );
};

export default Canvas;