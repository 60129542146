import React, {useRef, useState} from 'react';
import * as XLSX from "xlsx";
import languageEncoding from "detect-file-encoding-and-language";
import iconv from "iconv-lite";
import ErrorMessage from "../components/ErrorMessage";
import Button from "../components/Button";
import styled from "styled-components";
import LetterCheckbox from "../components/LetterCheckbox";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
`

const ResultItem = styled.div`
  display: flex;
  align-items: center;
  
  &:not(:last-child) {
    margin-bottom: 20px;    
  }
`

const ResultImage = styled.img`
  width: 120px;
  height: 120px;
  padding: 15px;
  border: 2px solid #eee;
  border-radius: 8px;
  margin-right: 20px;
`

const CheckBoxResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 20px;
  z-index: 9;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
`

const CheckBoxResult = styled.div`
  font-size: 24px;
  font-weight: 500;
  
  &:not(:last-child) {
    margin-right: 24px;    
  }
`

const DrawingBoard = () => {
    const fileInputRef = useRef()

    const [error, setError] = useState();
    const [taskTitle, setTaskTitle] = useState();
    const [results, setResults] = useState([]);

    const [checkboxResults, setCheckboxResults] = useState([

        {
            label: "А",
            value: 0
        },
        {
            label: "Б",
            value: 0
        },
        {
            label: "В",
            value: 0
        }
    ])

    const fileInputChangeHandler = e => {
        e.preventDefault();

        let files = e.target.files;
        let file = files[0];
        if (file) {
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary', codepage: 1251});
                const wsname = workbook.SheetNames[0];
                const ws = workbook.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

                let title = dataParse[1][0];
                let items = dataParse.filter((item, index) => index % 2 === 1).map(item => item[1])

                languageEncoding(file).then(fileInfo => {
                    if (fileInfo.encoding === "UTF-8") {
                        const encoded = iconv.encode(title, 'win1251');
                        title = iconv.decode(Buffer.from(encoded), 'utf-8')
                    }

                    try {
                        setResults(items)
                        setTaskTitle(title)
                        setError(null)
                    } catch (e) {
                        setError('Выберите корректный файл')
                    }
                })
            };
            reader.readAsBinaryString(file)
        }
    }

    const onCheckboxChange = e => {
        let a = document.querySelectorAll('[name=a]:checked').length;
        let b = document.querySelectorAll('[name=b]:checked').length;
        let c = document.querySelectorAll('[name=c]:checked').length;

        setCheckboxResults([
            {
                label: "А",
                value: a
            },
            {
                label: "Б",
                value: b
            },
            {
                label: "В",
                value: c
            }
        ])
    }

    return (
        <Wrapper>
            {!results.length
                ? <label>
                    {error && <ErrorMessage style={{marginBottom: 10}}>{error}</ErrorMessage>}
                    <input accept=".tsv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ref={fileInputRef} onChange={fileInputChangeHandler} type="file" style={{display: "none"}}/>
                    <Button>Открыть файл с результатами</Button>
                </label>
                : <ResultsWrapper style={{textAlign: 'center'}}>
                    <div className="headline-4" style={{width: 600, maxWidth: "100%"}}>{taskTitle}</div>
                    {results.map((url, index) => (
                        <ResultItem key={index}>
                            <ResultImage src={url} alt=""/>
                            <LetterCheckbox name="a" onChange={onCheckboxChange}>A</LetterCheckbox>
                            <LetterCheckbox name="b" onChange={onCheckboxChange}>Б</LetterCheckbox>
                            <LetterCheckbox name="c" onChange={onCheckboxChange}>В</LetterCheckbox>
                        </ResultItem>
                    ))}
                    <CheckBoxResults>
                        {checkboxResults.map((item, index) => (
                            <CheckBoxResult key={index}>
                                {item.label} - {item.value}
                            </CheckBoxResult>
                        ))}
                    </CheckBoxResults>
                </ResultsWrapper>
            }
        </Wrapper>
    );
};

export default DrawingBoard;