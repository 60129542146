import React from 'react';
import styled from "styled-components";

const StyledButton = styled.a`
  font-size: 14px;
  padding: 8px 15px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  color: #000;
  transition: .3s;
  cursor: pointer;
  border: 2px solid;

  i {
    font-size: 16px;
    margin-right: 5px;
  }
  
  &:hover {
    color: #333;
    background-color: #eee;
  }
`


const Button = (props) => {
    return (
        <StyledButton {...props}/>
    );
};

export default Button;