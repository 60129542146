import React, {useRef} from 'react';
import styled, {css} from 'styled-components'
import {useDetectOutsideClick} from "../hooks/useDetectOutsideClick.hook";

const Wrapper = styled.div`
  position:relative;
  z-index: 1;
`;

const Title = styled.div`
  cursor: pointer;
`;

const Content = styled.div`
  position: absolute;
  bottom: 100%;
  min-width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 20px rgb(0, 0, 0, .1);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #eee;
  
  ${props => !props.position && css`
    left: 0;
  `}
  
  ${props => props.position === 'left' && css`
    left: 0;
  `}
  
  ${props => props.position === 'right' && css`
    right: 0;
  `}
  
  ${props => props.position === 'center' && css`
    left: 50%;
    transform: translateX(-50%);
  `}
`;

const Popup = ({menuPosition, title, children}) => {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);

    return (
        <Wrapper ref={dropdownRef} className={isActive ? "active" : ""}>
            <Title onClick={onClick}>{title}</Title>
            {isActive &&
                <Content
                    position={menuPosition}>
                    {children}
                </Content>
            }
        </Wrapper>
    );
};

export default Popup;