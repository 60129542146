import React, {useRef, useState} from 'react';
import styled from "styled-components";
import * as XLSX from 'xlsx';
import HeatMapResult from "../components/HeatMapResult";
import Button from "./../components/Button";
import ErrorMessage from "./../components/ErrorMessage";
import iconv from "iconv-lite"
import languageEncoding from "detect-file-encoding-and-language"

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeatMap = () => {
    const fileInputRef = useRef()

    const [imageUrl, setImageUrl] = useState()
    const [heatData, setHeatData] = useState()
    const [taskTitle, setTaskTitle] = useState()
    const [error, setError] = useState()

    const fileInputChangeHandler = e => {
        e.preventDefault();
        setImageUrl(null)
        setHeatData(null)

        let files = e.target.files;
        let file = files[0];
        if (file) {
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary', codepage: 1251});
                const wsname = workbook.SheetNames[0];
                const ws = workbook.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
                const imgUrl = dataParse[1][0];
                let title = dataParse[1][1];

                languageEncoding(file).then(fileInfo => {
                    if (fileInfo.encoding === "UTF-8") {
                        const encoded = iconv.encode(title, 'win1251');
                        title = iconv.decode(Buffer.from(encoded), 'utf-8')
                    }

                    try {
                        setHeatData(dataParse.filter((item, index) => index % 2 === 1).map(item => JSON.parse(("[" + item[2] + "]").replaceAll("\\", ""))[0]))
                        setImageUrl(imgUrl)
                        setTaskTitle(title)
                        setError(null)
                    } catch (e) {
                        setError('Выберите корректный файл')
                    }
                })
            };
            reader.readAsBinaryString(file)
        }
    }

    return (
        <Wrapper>
            <div>
                {
                    !imageUrl
                        ? <label>
                            {error && <ErrorMessage style={{marginBottom: 10}}>{error}</ErrorMessage>}
                            <input accept=".tsv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ref={fileInputRef} onChange={fileInputChangeHandler} type="file" style={{display: "none"}}/>
                            <Button>Открыть файл с результатами</Button>
                        </label>
                        : <HeatMapResult taskTitle={taskTitle} setImageUrl={setImageUrl} imageUrl={imageUrl} data={heatData}/>
                }
            </div>
        </Wrapper>
    );
};

export default HeatMap;