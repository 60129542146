import React from 'react';
import styled from "styled-components";

const StyledErrorMessage = styled.div`
  color: red;
  font-size: 14px;
`

const ErrorMessage = (props) => {
    return (
        <StyledErrorMessage {...props}/>
    );
};

export default ErrorMessage;