import React from 'react';
import styled, {css} from "styled-components";
import {useSettingsContext} from "./HeatMapResult";

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const SelectionArea = styled.div`
  position: absolute;
  background-color: rgba(255,255,255,.7);
  border: 2px dashed #666;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
  font-weight: 500;
  cursor: pointer;
  transition: .3s;
  
  &:hover {
    background-color: rgba(255,120,120,.3);
  }
  
  ${props => !props.$imageFit && css`
    font-size: 24px
  `}
  
  &::selection {
    background-color: transparent;
    color: inherit;
  }
`

const Selected = ({selected, remove}) => {
    const {imageFit} = useSettingsContext()

    return (
        <Wrapper>
            {selected.map(({amount, id, ...styles}) => (
                <SelectionArea $imageFit={imageFit} style={styles} key={id} onClick={() => remove(id)}>
                    {amount} %
                </SelectionArea>
            ))}
        </Wrapper>
    );
};

export default Selected;