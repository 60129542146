import React from 'react';
import './App.scss';
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import {routes} from "./navigation/routes";
import HeatMap from "./pages/HeatMap";
import DrawingBoard from "./pages/DrawingBoard";

const App = () => {
    return (
        <BrowserRouter>
            <Link to={routes.heatmap}>ХитМап</Link>
            <Link to={routes.drawing_board}>Рисовалка</Link>

            <Switch>
                <Route path={routes.heatmap} component={HeatMap}/>
                <Route path={routes.drawing_board} component={DrawingBoard}/>
            </Switch>
        </BrowserRouter>
    );
};

export default App;