import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Button from "./Button";
import GhostButton from "./GhostButton";
import html2canvas from "html2canvas";
import ErrorMessage from "./ErrorMessage";
import Canvas from "./Canvas";
import LayersButton from "./LayersButton";

const DownloadWrapper = styled.div`
  padding: 30px;
  margin-bottom: 80px;
  text-align: center;
`

const BottomBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 20px;
  z-index: 9;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  
  &>* {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`

const settingsContext = createContext(null)

const HeatMapResult = ({taskTitle, imageUrl, data, setImageUrl}) => {
    const wrapperRef = useRef(null)

    const errors = {
        cors: "Установите расширение <a href='https://chrome.google.com/webstore/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf' target='_blank' rel=\"noreferrer\">Allow  CORS</a> и <a\n" +
            "            href=\"/\">перезагрузите страницу</a>.",
        not_found: "Изображение не найдено. <br/> <a href='/'>Назад</a>"
    }

    const [error, setError] = useState()

    const [img, setImg] = useState();
    const [imageFit, setImageFit] = useState(true)

    const [layers, setLayers] = useState({
        image: true,
        heatmap: true,
        selected: true
    })

    const run = useCallback(async () => {
        try {
            const res = await fetch(imageUrl);

            if (!res.ok) {
                throw new Error(errors.not_found)
            }

            const blob = await res.blob();
            const img = new Image();
            img.src = URL.createObjectURL(blob);
            img.onload = () => setImg(img);
        } catch (e) {
            console.log(e)
            setError(errors.not_found);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        run()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const downloadImg = () => {
        html2canvas(wrapperRef.current).then(canvas => {
            let a = document.createElement('a');
            let filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
            a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
            a.download = filename.replace(/\.[^/.]+$/, "") + ".jpg";
            a.click();
        });
    }

    const fitImage = () => {
        setImageFit(prev => !prev)
    }

    if (error) {
        return <ErrorMessage>
            <div style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: error}}/>
        </ErrorMessage>
    }

    if (!img) {
        return 'Загрузка...'
    }

    return (
        <>
            <settingsContext.Provider value={{imageFit, layers, setLayers}}>
                <DownloadWrapper ref={wrapperRef}>
                    <div className="headline-4" style={{width: 600, maxWidth: "100%"}}>{taskTitle}</div>
                    <Canvas {...{data, img, imageFit}}/>
                </DownloadWrapper>
                <BottomBar>
                    <GhostButton onClick={() => setImageUrl(null)}><i className="icon-arrow-left"/> Выбрать другой файл</GhostButton>
                    <LayersButton/>
                    <GhostButton onClick={fitImage}><i className="icon-size"/> {imageFit ? "Фактический размер" : "Во весь экран"}</GhostButton>
                    <Button onClick={downloadImg}><i className="icon-download"/> Скачать картинку</Button>
                </BottomBar>
            </settingsContext.Provider>
        </>
    );
};

export const useSettingsContext = () => {
    return useContext(settingsContext)
}

export default HeatMapResult;