import React from 'react';
import styled from "styled-components";

const Label = styled.label`
  cursor: pointer;
  
  &:not(:last-child) {
    margin-right: 10px;    
  }
  
  input {
    display: none;
    
    & + span {
      height: 48px;
      min-width: 48px;
      padding: 4px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: #eee;
      transition: .3s;
      font-weight: 500;
      
      &:hover {
        background-color: #e1e1e1;
      }
    }

    &:checked + span {
      background-color: #333;
      color: #fff;
    }
  }
`

const LetterCheckbox = ({children, ...props}) => {
    return (
        <Label>
            <input type="checkbox" {...props}/>
            <span>{children}</span>
        </Label>
    );
};

export default LetterCheckbox;