import React from 'react';
import GhostButton from "./GhostButton";
import Popup from "./Popup";
import Checkbox from "./Checkbox";
import styled from "styled-components";
import {useSettingsContext} from "./HeatMapResult";

const CheckBoxGroup = styled.div`
  & > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

const LayersButton = () => {
    const {layers, setLayers} = useSettingsContext()

    const updateLayer = (layer) => {
        setLayers(prev => {
            return {
                ...prev,
                ...layer
            }
        })
    }

    return (
        <div>
            <Popup menuPosition="center" title={
                <GhostButton>
                    <i className="icon-layers"/> Слои
                </GhostButton>
            }>
                <CheckBoxGroup style={{width: 180}}>
                    <Checkbox defaultChecked={layers.image} onChange={e => updateLayer({image: e.target.checked})}>Изображение</Checkbox>
                    <Checkbox defaultChecked={layers.heatmap} onChange={e => updateLayer({heatmap: e.target.checked})}>Тепловая карта</Checkbox>
                    <Checkbox defaultChecked={layers.selected} onChange={e => updateLayer({selected: e.target.checked})}>Выделенные области</Checkbox>
                </CheckBoxGroup>
            </Popup>
            <div>

            </div>
        </div>
    );
};

export default LayersButton;